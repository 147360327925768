import { useState, useEffect, useRef, useMemo } from 'react'
import { setStorage, useLocalStorage } from '@rpgtec/use-storage'
import { PaletteOptions } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { useActcast } from '../../hooks/useActcast'

import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import MarkerPopupContent from './MarkerPopupContent'
import ReactDOM from 'react-dom'
import CenterCoordinateDisplay from './CenterCoordinateDisplay'
import { MAPBOX } from '../../constants'
import { useDevicePhotoViewer } from '../../hooks/useDevicePhotoViewer'
mapboxgl.accessToken = MAPBOX.accessToken

type Devices = ReturnType<typeof useActcast>['state']['devices']['devices']
type Device = Devices[number]

type FeatureCollectionFeatures = Extract<
  Parameters<mapboxgl.GeoJSONSource['setData']>[0],
  { type: 'FeatureCollection' }
>['features']

const convertDevicesToPlaces = (
  deviceIds: string[],
  devices: ReturnType<typeof useActcast>['state']['devices']['devices']
): FeatureCollectionFeatures => {
  const _places = deviceIds.map((id) => {
    const data = devices[id]
    if (!data) {
      return null
    }
    const latTag = data.tags.find((tag) => /^lat:(.*)/.test(tag))
    const lat = latTag ? parseFloat(/^lat:(.*)/.exec(latTag)[1]) : 0
    const lonTag = data.tags.find((tag) => /^lon:(.*)/.test(tag))
    const lon = latTag ? parseFloat(/^lon:(.*)/.exec(lonTag)[1]) : 0
    return {
      type: 'Feature',
      properties: {
        data,
        foundness: data.foundness
      },
      geometry: {
        type: 'Point',
        coordinates: [lon, lat]
      }
    } satisfies FeatureCollectionFeatures[number]
  })
  return _places
}

export default function Map() {
  const mapContainer = useRef(null)
  const map = useRef<mapboxgl.Map>(null)
  const [lng, setLng] = useState(141.887446)
  const [lat, setLat] = useState(39.4)
  const [zoom, setZoom] = useState(9)
  const [mapLoaded, setMapLoaded] = useState(false)
  const {
    state: { devices, actcastDeviceGroups }
  } = useActcast()
  const [mode] = useLocalStorage<PaletteOptions['mode']>('themeMode', 'light')
  const { openDialog } = useDevicePhotoViewer()

  const mapStyleUrl = useMemo(() => (mode === 'dark' ? MAPBOX.styleUrl.dark : MAPBOX.styleUrl.light), [mode])

  useEffect(() => {
    if (map.current) return // initialize map only once
    setStorage('map', map)
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyleUrl,
      center: [lng, lat],
      zoom: zoom
    })
    // ナビゲーションコントロールの追加
    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right')
    map.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl
      }),
      'top-left'
    )

    map.current.on('load', () => {
      map.current.addSource('devicePlaces', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      })

      // TODO 色をTheme入れる
      const markerColor: string | mapboxgl.StyleFunction | mapboxgl.Expression = [
        'case',
        ['==', ['get', 'foundness'], 'Found'],
        '#4264fb',
        ['==', ['get', 'foundness'], 'NotFound'],
        '#C62C73',
        '#aaaaaa'
      ]
      map.current.addLayer({
        id: 'devicePlaces',
        type: 'circle',
        source: 'devicePlaces',
        paint: {
          'circle-color': markerColor,
          'circle-radius': 5,
          'circle-stroke-width': 9,
          'circle-stroke-color': markerColor,
          'circle-stroke-opacity': 0.2
        }
      })
    })

    map.current.on('move', () => {
      setLng(map.current.getCenter().lng)
      setLat(map.current.getCenter().lat)
      setZoom(map.current.getZoom())
    })

    map.current.on('click', 'devicePlaces', (e) => {
      const coordinates = e.features[0].geometry['coordinates'].slice()
      // propertiesの２階層目以降はstringになってしまうためパースが必要
      const data = JSON.parse(e.features[0].properties.data) as Device
      map.current.flyTo({ center: coordinates })

      const popupContentContainer = document.createElement('div')
      ReactDOM.render(<MarkerPopupContent data={data} openDialog={() => openDialog(data)} />, popupContentContainer)

      new mapboxgl.Popup({
        className: 'custom-popup',
        offset: 20,
        maxWidth: '350px'
      })
        .setLngLat(coordinates)
        .setDOMContent(popupContentContainer)
        .addTo(map.current)
    })

    map.current.on('mouseenter', 'devicePlaces', () => {
      map.current.getCanvas().style.cursor = 'pointer'
    })

    map.current.on('mouseleave', 'devicePlaces', () => {
      map.current.getCanvas().style.cursor = ''
    })

    map.current.on('styledata', () => {
      if (map.current.isStyleLoaded()) {
        setMapLoaded(true)
      }
    })
  })

  // マーカー情報更新
  useEffect(() => {
    if (
      !mapLoaded ||
      devices.loading ||
      actcastDeviceGroups.loading ||
      !actcastDeviceGroups.initializedFlag ||
      !actcastDeviceGroups.currentActcastDeviceGroupId
    )
      return

    const group = actcastDeviceGroups.actcastDeviceGroups.data[actcastDeviceGroups.currentActcastDeviceGroupId]
    const _places = convertDevicesToPlaces(group.actcastDevices, devices.devices)
    const newData: Parameters<mapboxgl.GeoJSONSource['setData']>[0] = {
      type: 'FeatureCollection',
      features: _places
    }
    const source = map.current.getSource('devicePlaces') as mapboxgl.GeoJSONSource
    source.setData(newData)
  }, [
    mapLoaded,
    devices,
    actcastDeviceGroups.loading,
    actcastDeviceGroups.initializedFlag,
    actcastDeviceGroups.currentActcastDeviceGroupId,
    actcastDeviceGroups.actcastDeviceGroups.data
  ])

  // 地図テーマ切り替え
  useEffect(() => {
    map.current.setStyle(mapStyleUrl)
  }, [mapStyleUrl])

  const popupStyle = {
    '.custom-popup': {
      '.mapboxgl-popup-tip': {
        borderWidth: '5px',
        borderTopColor: 'rgba(0, 128, 128, 0.5)'
      },
      '.mapboxgl-popup-content': {
        padding: '10px 15px 15px',
        backgroundColor: 'rgba(0, 128, 128, 0.5)',
        color: 'white'
      }
    }
  }

  return (
    <>
      <Box ref={mapContainer} sx={{ width: '100%', height: '100%', ...popupStyle }} />
      <CenterCoordinateDisplay
        sx={{ position: 'absolute', padding: '10px', left: 0, top: 0, paddingTop: '54px' }}
        latitude={lat}
        longitude={lng}
      />
    </>
  )
}
