import { useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { actcastDeviceGroupsState } from './recoil/deviceGrups'
import { deviceState } from './recoil/devices'
import { useFetchDevicesFromGroupsBase } from './recoil/devices'
import { getAPIAccessToken } from '../lib/auth0'

export const useAnalytics = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [actcastDeviceGroups, setActcastDeviceGroups] = useRecoilState(actcastDeviceGroupsState)
  const { fetchDevicesFromGroupsBase } = useFetchDevicesFromGroupsBase()
  const [devices] = useRecoilState(deviceState)

  return {
    state: {
      actcastDeviceGroups,
      devices
    },
    setCurrentActcastDeviceGroupId: async (actcastDeviceGroupId: string) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const devices = await fetchDevicesFromGroupsBase({
        actcastDeviceGroupId,
        accessToken
      })
      setActcastDeviceGroups({ ...actcastDeviceGroups, currentActcastDeviceGroupId: actcastDeviceGroupId })

      return { devices }
    }
  }
}
