import type { EChartsOption, ECharts } from 'echarts'
import Box from '@mui/material/Box'
import { useEffect, useRef } from 'react'
import { init, getInstanceByDom } from 'echarts'

export type SpeedMeterProps = {
  title: string
  data: { value: number }[]
}

export default function SpeedMeter(props: SpeedMeterProps) {
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let chart: ECharts
    if (chartRef.current !== null) {
      chart = init(chartRef.current, 'light')
    }
    function resizeChart() {
      chart?.resize()
    }
    window.addEventListener('resize', resizeChart)

    return () => {
      chart?.dispose()
      window.removeEventListener('resize', resizeChart)
    }
  }, [])

  useEffect(() => {
    const option: EChartsOption = {
      title: {
        text: props.title
      },
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          type: 'gauge',
          progress: {
            show: true,
            width: 18
          },
          axisLine: {
            lineStyle: {
              width: 18
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            length: 15,
            lineStyle: {
              width: 2,
              color: '#999'
            }
          },
          axisLabel: {
            distance: 30,
            color: '#999',
            fontSize: 10
          },
          anchor: {
            show: true,
            showAbove: true,
            size: 25,
            itemStyle: {
              borderWidth: 10
            }
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            fontSize: 40,
            formatter: '{value} %',
            offsetCenter: [0, '70%']
          },
          data: props.data
        }
      ]
    }
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      chart?.setOption(option)
    }
  }, [props.data, props.title])

  return (
    <Box sx={{ width: '100%' }}>
      <div ref={chartRef} style={{ width: '100%', height: '100%', minHeight: '300px' }} />
    </Box>
  )
}
