import type { EChartsOption, ECharts } from 'echarts'
import Box from '@mui/material/Box'
import { useEffect, useRef } from 'react'
import { init, getInstanceByDom } from 'echarts'

export type BarChartProps = {
  title: string
  category: string[]
  legend: string[]
  data: { name: string; data: number[] }[]
}

export default function BarChart(props: BarChartProps) {
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let chart: ECharts
    if (chartRef.current !== null) {
      chart = init(chartRef.current, 'light')
    }
    function resizeChart() {
      chart?.resize()
    }
    window.addEventListener('resize', resizeChart)

    return () => {
      chart?.dispose()
      window.removeEventListener('resize', resizeChart)
    }
  }, [])

  useEffect(() => {
    const series = []
    for (const data of props.data) {
      series.push({
        name: data.name,
        type: 'bar',
        data: data.data
      })
    }
    const option: EChartsOption = {
      title: {
        text: props.title
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: props.legend
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: { show: true }
        }
      },
      calculable: true,
      xAxis: [
        {
          type: 'category',
          data: props.category
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: series
    }
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      chart?.setOption(option)
    }
  }, [props.category, props.data, props.legend, props.title])

  return (
    <Box sx={{ width: '100%' }}>
      <div ref={chartRef} style={{ width: '100%', height: '100%', minHeight: '300px' }} />
    </Box>
  )
}
