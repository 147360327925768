import type { ActcastDeviceGroupsState } from '../../../hooks/recoil/deviceGrups'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

type Props = {
  actcastDeviceGroups: ActcastDeviceGroupsState
  onChange: (groupId: string) => void
}

export default function ActcastDeviceGroupMenu(props: Props) {
  // const { state: { actcastDeviceGroups }, setCurrentActcastDeviceGroupId } = useAnalytics()

  const { actcastDeviceGroups } = props

  if (!actcastDeviceGroups.initializedFlag || actcastDeviceGroups.loading) {
    return <>loading...</>
  }

  const menuItems = actcastDeviceGroups.actcastDeviceGroups.ids.map((id) => {
    const group = actcastDeviceGroups.actcastDeviceGroups.data[id]
    return (
      <MenuItem key={group._id} value={group._id}>
        {group.name}
      </MenuItem>
    )
  })

  const handleChange = (e: SelectChangeEvent) => {
    props.onChange(e.target.value)
    // setCurrentActcastDeviceGroupId(e.target.value)
  }

  const selectValue = actcastDeviceGroups.currentActcastDeviceGroupId ?? actcastDeviceGroups.actcastDeviceGroups[0]._id

  return (
    <Box>
      <FormControl fullWidth>
        <Select value={selectValue} onChange={handleChange}>
          {menuItems}
        </Select>
      </FormControl>
    </Box>
  )
}
