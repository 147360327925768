import React from 'react'
import { useLocalStorage } from '@rpgtec/use-storage'
import { createTheme, ThemeProvider as MuiThemeProvider, PaletteOptions } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import locale from 'date-fns/locale/ja'

export default function ThemeProvider(props: { children: React.ReactNode }) {
  const [mode] = useLocalStorage<PaletteOptions['mode']>('themeMode', 'light')

  const theme = createTheme({
    palette: {
      mode,
      primary: { main: '#ff6600' },
      secondary: { main: '#556677' },
      background:
        mode === 'dark'
          ? ({
              default: '#2a2a2a',
              header: 'rgba(0, 0, 0, 1)',
              sideMenu: '#2a2a2a',
              transparent: 'rgba(43,43,43,0.8)'
            } as unknown)
          : ({
              default: '#eeeeee',
              header: 'rgba(255, 255, 255, 1)',
              sideMenu: 'rgba(255, 255, 255, 1)',
              transparent: 'rgba(255,255,255,0.8)'
            } as unknown)
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        #root {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }
      `
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            borderRadius: 3,
            boxShadow:
              '0px 1px 1px rgba(0, 0, 0, .1), inset 0px -18px 6px rgba(0, 0, 0, .05), inset 0px 0px 0px 1px rgba(0, 0, 0, .1)',
            color: 'white'
          }
        },
        variants: [
          {
            props: { size: 'medium' },
            style: { height: 32 }
          },
          {
            props: { size: 'small' },
            style: { height: 28 }
          }
        ]
      },
      MuiDivider: {
        variants: [
          {
            props: { variant: 'dashed' } as unknown,
            style: { borderStyle: 'dashed' }
          }
        ]
      }
    }
  })

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        {props.children}
      </LocalizationProvider>
    </MuiThemeProvider>
  )
}
